"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = getGeoLocationStatus;
var _logger = _interopRequireDefault(require("logger"));
var _geoLocationStatus = require("./geoLocationStatus");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function getGeoLocationStatus(countryCode) {
  if (countryCode == null) {
    _logger["default"].info('missing country code, returning with not allowed');
    return _geoLocationStatus.GEOLOCATION_NOT_ALLOWED;
  }
  switch (countryCode.toLowerCase()) {
    case 'uk':
    case 'gb':
      return _geoLocationStatus.GEOLOCATION_ALLOWED;
    case 'de':
      return _geoLocationStatus.GEOLOCATION_COMING_SOON;
    case 'us':
      return _geoLocationStatus.GEOLOCATION_USA;
    default:
      return _geoLocationStatus.GEOLOCATION_NOT_ALLOWED;
  }
}